import React, { useContext, useEffect } from "react";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { CONSTS } from "../../CONST";
import { useMessage } from "../../hooks/message.hook";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory, withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";
import { PhoneInput } from "../../components/PhoneInput";

const Step4Page = () => {
  const globalCont = useContext(GlobalContext);
  const message = useMessage();
  const { loading, request } = useHttp();
  let nextButtonDisabled = true;
  const history = useHistory();
  const handleInput = ({ target: { value } }) =>
    globalCont.setApp({
      ...globalCont.app,
      phone: value,
    });

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  useEffect(() => {
    globalCont.setStep(3);
  }, [globalCont]);

  if (loading) {
    return <Loader />;
  }

  const saveHandler = async () => {
    try {
      let phoneFormatted = globalCont.app.phone.replace("+38", "");
      phoneFormatted = phoneFormatted.replace(/[-_()\s]/g, "");
      if (phoneFormatted.length !== 10) {
        message("Заповніть, будь-ласка, номер телефону");
        return
      }
      const resp = await request(
        `${CONSTS.SERVER_PATH}createOnlineApp.php`,
        "POST",
        {
          deviceCode: globalCont.app.deviceCode,
          deviceName: globalCont.app.deviceName,
          evaluation: globalCont.evaluation,
          condition: globalCont.condition,
          name: globalCont.app.name,
          email: globalCont.app.email,
          phone: phoneFormatted,
          partnerId: localStorage.getItem("partnerId"),
        }
      );
      if (resp.status === "success") {
        message(resp.message);
        globalCont.setCost(resp.data.cost);
        globalCont.setImage(resp.data.image);
        history.push(`/success`);
      }
    } catch (e) {}
  };

  const changeHandler = (event) => {
    //console.log(event);
    globalCont.setApp({
      ...globalCont.app,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      {!loading && (
        <div>
          <div className="card" style={{ padding: "2rem" }}>
            <div className="input-field">
              <input
                placeholder="Введіть ПІБ"
                id="name"
                type="text"
                value={globalCont.app.name}
                onChange={changeHandler}
                name="name"
              />
              <label htmlFor="link">ПІБ</label>
            </div>
            {/* <div className="input-field">
              <input
                placeholder="Введіть email"
                id="email"
                type="email"
                value={globalCont.app.email}
                onChange={changeHandler}
                name="email"
              />
              <label htmlFor="link">Email</label>
            </div> */}
            <label htmlFor="link">Номер телефону</label>
            <div className="input-field">
              <div>
                <PhoneInput
                  value={globalCont.app.phone}
                  onChange={handleInput}
                ></PhoneInput>
              </div>
            </div>
            <div className="input-field">
              <input
                id="deviceName"
                type="text"
                value={globalCont.app.deviceName}
                onChange={changeHandler}
                name="deviceName"
              />
              <label htmlFor="deviceName">Найменування пристрою</label>
            </div>
            <button
              className="waves-effect waves-light btn"
              onClick={saveHandler}
            >
              <i className="material-icons left">arrow_forward</i>
              Оцінити
            </button>
          </div>
          <StepButtons nextButtonDisabled={nextButtonDisabled} />
        </div>
      )}
    </>
  );
};
export default withRouter(Step4Page);
