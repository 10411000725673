import React, { useContext, useEffect } from "react";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";

const Step2Page = () => {
  const { loading } = useHttp();
  const globalCont = useContext(GlobalContext);
  let nextButtonDisabled = false;

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  const handleOptionEvaluationChange = (event) => {
    globalCont.setEvaluation(event.target.value);
    globalCont.setApp({ ...globalCont.app, evaluation: event.target.value })
  };

  useEffect(() => {
    globalCont.setStep(1);
  }, [globalCont]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div className="card" style={{ padding: "2rem" }}>
          <div className="row">
            <label style={{ fontSize: "20px" }}>
              Оцініть зовнішній вигляд пристрою
            </label>
            <p>
              <label>
                <input
                  name="evaluation"
                  type="radio"
                  value="1"
                  checked={globalCont.evaluation === "1"}
                  onChange={handleOptionEvaluationChange}
                />
                <span>
                  1. Пристрій з явними дефектами корпусу, наявність дефектів на
                  дисплеї: плями (білі, жовті, зелені), засвітки, биті пікселі,
                  тріщини і відколи. Множинні глибокі подряпини, добре помітні
                  вм'ятини, явні сліди падіння, відсутність деталей, але при
                  цьому основні вузли повністю працездатні.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input
                  name="evaluation"
                  type="radio"
                  value="2"
                  checked={globalCont.evaluation === "2"}
                  onChange={handleOptionEvaluationChange}
                />
                <span>
                  2. Пристрій з активними слідами експлуатації. Можливі незначні
                  тріщини корпусу, сильні потертості, поодинокі глибокі
                  подряпини, незначні сліди падінь. Наявність не більше двох
                  битих пікселів.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input
                  name="evaluation"
                  type="radio"
                  value="3"
                  checked={globalCont.evaluation === "3"}
                  onChange={handleOptionEvaluationChange}
                />
                <span>
                  3. Пристрій з незначними слідами використання. Можливі не
                  глибокі подряпини на корпусі, незначні потертості на дисплеї,
                  затерті кути, але не сліди падінь.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input
                  name="evaluation"
                  type="radio"
                  value="4"
                  checked={globalCont.evaluation === "4"}
                  onChange={handleOptionEvaluationChange}
                />
                <span>
                  4. Пристрій в чудовому стані, майже новий. Можливі незначні
                  мікроподряпини на задній кришці. Неприпустимо наявність
                  пошкоджень на дисплеї, слідів падіння і інших дефектів.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input
                  name="evaluation"
                  type="radio"
                  value="5"
                  checked={globalCont.evaluation === "5"}
                  onChange={handleOptionEvaluationChange}
                />
                <span>
                  5. Новий пристрій, в ідеальному стані, без найменших дефектів.
                  Наявність будь-яких потертостей, подряпин, відколів, плям,
                  вм'ятин та інших дефектів не допустимо. Наявність фіскального
                  чека з датою, не пізніше ніж 6 місяців від дня набуття даного
                  пристрою.
                </span>
              </label>
            </p>
          </div>
          <StepButtons nextButtonDisabled={nextButtonDisabled}/>
        </div>
      )}
    </>
  );
};
export default withRouter(Step2Page);