import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";

export const SuccessPage = () => {
  const globalCont = useContext(GlobalContext);
  const start = `start/?partnerId=${localStorage.getItem("partnerId")}`;

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}
    >
      <div className="col s12 m7">
        <h2 className="header">Оцінка вашого пристрою</h2>
        <div className="card horizontal">
          <div className="card-image">
            <img src={globalCont.image} alt=""></img>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <span className="card-title">
                Ваш пристрій {globalCont.app.deviceName} оцінено
              </span>
              <span className="card-title">
                до <b>{globalCont.cost}</b>грн*
              </span>
              <p>
            *Оцінка є попередньою. Остаточну вартість пристрою буде озвучено експертом у магазині, після детальної перевірки вашого пристрою. 
         </p>
            </div>
          </div>
        </div>
        <div className="card-action">
          <a href={start} className="waves-effect waves-light btn">
            <i className="material-icons left">arrow_forward</i>Оцінити інший
            пристрій
          </a>
        </div>
        
      </div>
    </div>
  );
};
