import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

export const Navbar = () => {
  const globalCont = useContext(GlobalContext);

  const preventHandler = (event) => {
    event.preventDefault();
    var elems = document.querySelectorAll(".sidenav");
    window.M.Sidenav.init(elems, {});
  };

  return (
    <>
      <nav className="navbar-style">
        <div className="nav-wrapper container" style={{ padding: "0 2rem" }}>
          {/*  <span className="brand-logo container">Калькулятор</span> */}
          <a
            href="/"
            data-target="mobile-demo"
            className="sidenav-trigger"
            onClick={preventHandler}
          >
            <i className="material-icons">menu</i>
          </a>
          <ul
            id="nav-mobile"
            className="hide-on-med-and-down"
            style={styles.ul}
          >
            <li className={globalCont.step === 0 ? "active text-style" : "text-style"}>
              <NavLink to="/step1">Вибір пристрою</NavLink>
            </li>
            <li className={globalCont.step === 1 ? "active text-style" : "text-style"}>
              <NavLink to="/step2">Стан</NavLink>
            </li>
            <li className={globalCont.step === 2 ? "active text-style" : "text-style"}>
              <NavLink to="/step3">Комплектація</NavLink>
            </li>
            <li className={globalCont.step === 3 ? "active text-style" : "text-style"}>
              <NavLink to="/step4">Заявка</NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <ul className="sidenav" id="mobile-demo">
      <li className={globalCont.step === 0 ? "active text-style" : "text-style"}>
          <NavLink to="/step1">Вибір пристрою</NavLink>
        </li>
        <li className={globalCont.step === 1 ? "active text-style" : "text-style"}>
          <NavLink to="/step2">Стан</NavLink>
        </li>
        <li className={globalCont.step === 2 ? "active text-style" : "text-style"}>
          <NavLink to="/step3">Комплектація</NavLink>
        </li>
        <li className={globalCont.step === 3 ? "active text-style" : "text-style"}>
          <NavLink to="/step4">Заявка</NavLink>
        </li>
      </ul>
    </>
  );
};
const styles = {
  ul: {
    justifyContent: "center",
    alignItems: "center",
  },
};
