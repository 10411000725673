import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useRoutes } from "./routes";
import { Navbar } from "./components/Navbar";
import "materialize-css";
import { GlobalContext } from "./context/GlobalContext";
import { CONSTS } from "./CONST";
import { useHttp } from "./hooks/http.hook";
import { useMessage } from "./hooks/message.hook";

function App() {
  const routes = useRoutes();
  const { request, error, clearError } = useHttp();
  const [step, setStep] = useState(0);
  const message = useMessage();
  const [app, setApp] = useState({
    name: "",
    email: "",
    phone: "",
    deviceCode: "",
    deviceName: "",
    evaluation: "",
    condition: "",
  });
  const [price, setPrice] = useState();
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);

  const [selectedType, setSelectedType] = useState("Оберіть тип пристрою");
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedModel, setSelectedModel] = useState();

  const [condition, setCondition] = useState("5");
  const [evaluation, setEvaluation] = useState("5");

  const [cost, setCost] = useState(0);

  const [nextButtonStep1Disabled, setNextButtonStep1Disabled] = useState(true);

  const [isStartPage, setIsStartPage] = useState(true);
  const [image, setImage] = useState("");

  useEffect(() => {
    message(error);
    clearError();
  }, [error, message, clearError]);

  const fetchApps = useCallback(async () => {
    try {
      const resp = await request(`${CONSTS.SERVER_PATH}getPrice.php`, "GET");
      const defaultType = [{ name: "Оберіть тип пристрою" }];
      const allTypes = defaultType.concat(resp.data);
      setPrice(allTypes);
    } catch (e) {}
  }, [request]);

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  return (
    <GlobalContext.Provider
      value={{
        step,
        setStep,
        app,
        setApp,
        price,
        models,
        setModels,
        brands,
        setBrands,
        selectedType,
        setSelectedType,
        selectedBrand,
        setSelectedBrand,
        selectedModel,
        setSelectedModel,
        nextButtonStep1Disabled,
        setNextButtonStep1Disabled,
        condition,
        setCondition,
        evaluation,
        setEvaluation,
        cost,
        setCost,
        isStartPage,
        setIsStartPage,
        image,
        setImage
      }}
    >
      <Router>
        {!isStartPage && <Navbar />}
        {isStartPage && <div >{routes}</div>}
        {!isStartPage &&<div className="container">{routes}</div>}
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;
