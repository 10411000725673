import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import  Step1Page  from "./pages/mobileSteps/Step1Page";
import  Step2Page  from "./pages/mobileSteps/Step2Page";
import  Step3Page  from "./pages/mobileSteps/Step3Page";
import  Step4Page  from "./pages/mobileSteps/Step4Page";
import  { GlobalContext } from "./context/GlobalContext";
import { SuccessPage } from "./pages/mobileSteps/SuccessPage";
import { StartPage } from "./pages/mobileSteps/StartPage";


export const useRoutes = () => {
  
  const globalCont = useContext(GlobalContext);
  
  useEffect(() => {
   
  }, [globalCont]);

  return (
    <Switch>
      <Route path="/step1" exact>
        <Step1Page />
      </Route> 
      <Route path="/step2"  exact>
        <Step2Page />
      </Route>
      <Route path="/step3"  exact>
        <Step3Page />
      </Route>
      <Route path="/step4" exact>
        <Step4Page />
      </Route>
      <Route path="/success" exact>
        <SuccessPage />
      </Route>
      <Route path="/start" exact>
        <StartPage />
      </Route>
      <Redirect to="/start" />
    </Switch>
  );
};
