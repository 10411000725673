import InputMask from 'react-input-mask';

export const PhoneInput = (props) => {
  return (
    <InputMask 
      mask='(+38)999 999 99 99' 
      value={props.value} 
      onChange={props.onChange}>
    </InputMask>
  );
}