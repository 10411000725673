import React, { useContext, useEffect } from "react";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";

const Step3Page = () => {
  const { loading } = useHttp();
  const globalCont = useContext(GlobalContext);
  
  let nextButtonDisabled = false;

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  const handleOptionConditionChange = (event) => {
    globalCont.setCondition(event.target.value);
    globalCont.setApp({ ...globalCont.app, condition: event.target.value })
  };

  useEffect(() => {
    globalCont.setStep(2);
  }, [globalCont]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div>
          <div className="card" style={{ padding: "2rem" }}>
            <div className="row">
              <label style={{ fontSize: "20px" }}>
                Оцініть комплектацію пристрою
              </label>
              <p>
                <label>
                  <input
                    name="condition"
                    type="radio"
                    value="1"
                    checked={globalCont.condition === "1"}
                    onChange={handleOptionConditionChange}
                  />
                  <span>
                    1. Комплект відсутня. В наявності тільки пристрій.
                  </span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="condition"
                    type="radio"
                    value="2"
                    checked={globalCont.condition === "2"}
                    onChange={handleOptionConditionChange}
                  />
                  <span>
                    2. В наявності тільки зарядний пристрій або заводська
                    упаковка
                  </span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="condition"
                    type="radio"
                    value="3"
                    checked={globalCont.condition === "3"}
                    onChange={handleOptionConditionChange}
                  />
                  <span>
                    3. В наявності: зарядний пристрій, заводська упаковка,
                    документи (можливо відсутність документів).
                  </span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="condition"
                    type="radio"
                    value="4"
                    checked={globalCont.condition === "4"}
                    onChange={handleOptionConditionChange}
                  />
                  <span>4. Повний комплект який гарантує виробник.</span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="condition"
                    type="radio"
                    value="5"
                    checked={globalCont.condition === "5"}
                    onChange={handleOptionConditionChange}
                  />
                  <span>
                    5. Полный комплект который гарантирует производитель.
                    Обязательно наличие гарантийного талона и чека о покупке.
                  </span>
                </label>
              </p>
            </div>
          </div>
          <StepButtons nextButtonDisabled={nextButtonDisabled} />
        </div>
      )}
    </>
  );
};

export default withRouter(Step3Page);