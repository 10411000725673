import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Background from "../../assets/tradein.png";

export const StartPage = () => {
  const history = useHistory();

  const clickHandler = (event) => {
    event.preventDefault();
    history.push(`/step1`);
  };

  useEffect(() => {
    let params = (new URL(document.location)).searchParams; 
    localStorage.setItem('partnerId', params.get('partnerId'))
  }, []);
 
  return (
    <div>
      <div>
        <div style={styles.image}>
          <a
            href="/"
            onClick={clickHandler}
            className="semi-transparent-button with-border"
          >
            Обміняти
          </a>
        </div>
      </div>
      {/* <div className="card-content">
        <iframe
          title="calcframe"
          width="100%"
          height="650px"
          src="https://1.pershyy.top/start?partnerId=ee8e898d-2a39-11ec-a330-0050569b8dc6"
        ></iframe>
      </div> */}
    </div>
  );
};

const styles = {
  image: {
    width: "100%",
    height: "600px",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
