import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { StepButtons } from "../../components/StepButtons";

const Step1Page = () => {
  const globalCont = useContext(GlobalContext);
  
  useEffect(() => {
    globalCont.setStep(0);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  const changeTypeHandler = (event) => {
    globalCont.setSelectedType(event.target.value);
    globalCont.setSelectedBrand("");
    globalCont.setSelectedModel("");
    const brands = globalCont.price.find((el) => el.name === event.target.value)
      .list;
    const defaultBrand = [{ name: "Оберіть виробника пристрою" }];
    const allBrands = defaultBrand.concat(brands);
    globalCont.setBrands(allBrands);
  };
  const changeBrandHandler = (event) => {
    globalCont.setSelectedBrand(event.target.value);
    globalCont.setSelectedModel("");
    const mdls = globalCont.brands.find((el) => el.code === event.target.value)
      .models;
    const defaultModel = [{ name: "Оберіть модель пристрою" }];
    const allModels = defaultModel.concat(mdls);
    globalCont.setModels(allModels);
  };
  const changeModelHandler = (event) => {
    globalCont.setSelectedModel(event.target.value);
    //console.log(event.target.value);
    globalCont.setApp({
      ...globalCont.app,
      deviceName: event.target.selectedOptions[0].innerText,
      deviceCode: event.target.value,
    });

    if (globalCont.selectedModel !== "Оберіть модель пристрою") {
      globalCont.setNextButtonStep1Disabled(false);
    }
  };

  return (
    <>
      {
        <div>
          <div className="card" style={{ padding: "2rem" }}>
            <div className="card-content">
              <span className="card-title grey-text text-darken-4">
                Оберіть пристрій
              </span>
            </div>
            <div className="row">
              <select
                style={{ display: "block" }}
                value={globalCont.selectedType}
                onChange={changeTypeHandler}
              >
                <option value="DEFAULT" disabled >
                  Оберіть тип пристрою
                </option>
                {globalCont.price &&
                  globalCont.price.map((type, index) => {
                    return (
                      <option key={index} value={type.name}>
                        {type.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="row">
              <select
                style={{ display: "block" }}
                value={globalCont.selectedBrand}
                onChange={changeBrandHandler}
              >
                <option value="DEFAULT" disabled>
                  Оберіть виробника пристрою
                </option>
                {globalCont.selectedType !== "" &&
                  globalCont.brands.map((brand, index) => {
                    return (
                      <option key={index} value={brand.code}>
                        {brand.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="row">
              <select
                style={{ display: "block" }}
                value={globalCont.selectedModel}
                onChange={changeModelHandler}
              >
                <option value="DEFAULT" disabled>
                  Оберіть модель пристрою
                </option>
                {globalCont.selectedBrand &&
                  globalCont.models.map((model, index) => {
                    return (
                      <option key={index} value={model.code} name={model.name}>
                        {model.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <StepButtons
            nextButtonDisabled={globalCont.nextButtonStep1Disabled}
          />
        </div>
      }
    </>
  );
};

export default withRouter(Step1Page);
